
















import {Component, Prop, Watch, Vue} from 'vue-property-decorator'
import {PrivateCategoryCollection} from '@/model/collection/PrivateCategoryCollection'
import {FilterPrivateCategorySchema} from '@/schema/resource/PrivateCategory/FilterPrivateCategorySchema'

@Component
export default class FilterPrivateCategory extends Vue {
  @Prop({type: Object, required: true}) collection!: PrivateCategoryCollection

  schema = new FilterPrivateCategorySchema()

  async created() {
    await this.populateFilterOptions()
  }

  async populateFilterOptions() {
    this.collection.resource = this.schema

    const promises: Array<Promise<any>> = []

    await this.$await.run('softQuery', () => Promise.all(promises))
  }

  async doFilter() {
    this.$emit('submit')
    await this.$await.run('softQuery', () => this.collection.queryAsPage())
  }
}
