/**
 * Filter Schema of PrivateCategory
 * @author Simpli CLI generator
 */
import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldSet, FieldComponent} from '@simpli/meta-schema'
import * as Component from '@simpli/vue-input'
import {IPrivateCategoryCollectionResourcesHolder} from '@/model/collection/PrivateCategoryCollection'
import {PrivateCategory} from '@/model/resource/PrivateCategory'

/* TODO: review generated schema */
export class FilterPrivateCategorySchema extends DefaultSchema
  implements IPrivateCategoryCollectionResourcesHolder {
  readonly name = 'FilterPrivateCategory'

  readonly fieldSet: FieldSet<PrivateCategory> = {}
}
