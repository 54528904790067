


















































































































import {Component, Prop, Watch, Mixins} from 'vue-property-decorator'
import {MixinRouteMatch} from '@/components/mixins/MixinRouteMatch'
import FilterToggle from '@/components/FilterToggle.vue'
import FilterPrivateCategory from '@/components/filters/FilterPrivateCategory.vue'
import {PrivateCategory} from '@/model/resource/PrivateCategory'
import {PrivateCategoryCollection} from '@/model/collection/PrivateCategoryCollection'
import {ListPrivateCategorySchema} from '@/schema/resource/PrivateCategory/ListPrivateCategorySchema'
import {ExportPrivateCategorySchema} from '@/schema/resource/PrivateCategory/ExportPrivateCategorySchema'

@Component({
  components: {FilterToggle, FilterPrivateCategory},
})
export default class ListPrivateCategoryView extends Mixins(MixinRouteMatch) {
  schema = new ListPrivateCategorySchema()
  collection = new PrivateCategoryCollection()
  filterOpen = false

  async created() {
    if (this.hasQueryParams) {
      this.updateObjectFromRoute(this.collection)
    }
    await this.$await.run('hardQuery', () => this.collection.queryAsPage())
  }

  @Watch('collection', {deep: true})
  collectionEvent() {
    this.updateRouteFromObject(this.collection)
  }

  goToPersistView(item: PrivateCategory) {
    this.$nav.pushByName('editPrivateCategory', item.$id)
  }

  async downloadXlsx() {
    const {ascending, orderBy, page, limit, ...params} = this.collection.params

    const coll = new PrivateCategoryCollection()
      .clearFilters()
      .addFilter(params)

    await coll.listExportPrivateCategory()
    this.$xlsx.downloadFromSchema(coll.items, new ExportPrivateCategorySchema())
  }
}
